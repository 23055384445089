import React from 'react';
import {
    Container,
    Title,
  Ol
} from './styles';
import {Header, InfoContainer, InfoText, InfoTitle} from "../Cookies/styles";
import {Strong} from "../../Shared/Modals/TransferModal/styles";
import {Li} from "../PaymentOptions/styles";

const WhatItsSedee = () => {
    return (
        <Container>
            <Header>
                <Title>Políticas de cancelación</Title>
            </Header>
            <InfoContainer>
                <InfoTitle>
                  ¿Qué ocurre si no puedo viajar debido a circunstancias excepcionales en mi lugar de origen?
                </InfoTitle>
                <InfoText>
                  En Sedee, comprendemos que pueden surgir circunstancias excepcionales que estén fuera de su control y que afectan su capacidad para viajar. En tales casos, le recomendamos seguir estos pasos:
                </InfoText>
                <Ol>
                  <Li><Strong>Revisar las Condiciones del Seguro:</Strong> Si ha adquirido un seguro para su evento a través de nosotros, le aconsejamos revisar las condiciones y coberturas específicas proporcionadas por su póliza. Los seguros a menudo incluyen protección en casos de circunstancias excepcionales, como cancelaciones por situaciones imprevistas en tu lugar de origen.</Li>
                  <Li><Strong>Consultar las Condiciones del Establecimiento:</Strong> Si no ha adquirido un seguro de viaje o si las circunstancias excepcionales no están cubiertas por su póliza, le recomendamos ponerse en contacto directamente con el establecimiento en el que realizó su reserva. Cada establecimiento puede tener políticas de cancelación y modificación diferentes, por lo que es importante conocer sus condiciones específicas y negociar posibles soluciones directamente con ellos.</Li>
                </Ol>
                <InfoText>
                  En Sedee, estamos comprometidos a brindarle asistencia y apoyo en situaciones imprevistas. Si tiene alguna pregunta adicional o necesita orientación sobre cómo proceder en caso de circunstancias excepcionales en tu lugar de origen, no dude en comunicarse con nuestro equipo de atención al cliente. Estamos aquí para ayudarle a encontrar la mejor solución posible y garantizar que tu experiencia de viaje sea lo más fluida y segura posible.
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                  ¿Cómo se tramitan las devoluciones por cancelación dentro del tiempo estipulado?
                </InfoTitle>
                <InfoText>
                  En Sedee, comprendemos que pueden surgir circunstancias inesperadas que requieran la cancelación de una reserva dentro del plazo establecido. Para garantizar una experiencia sin complicaciones, tramitamos las devoluciones de la siguiente manera:
                </InfoText>
                <InfoText>
                  Mismo método de pago: Las devoluciones por cancelación se realizarán a través del mismo método de pago que se utilizó para efectuar la reserva inicial. Esto significa que si realizaste el pago con tarjeta de crédito, la devolución se efectuará a esa misma tarjeta. Si optaste por una transferencia bancaria, el reembolso se procesará mediante transferencia bancaria
                </InfoText>
                <InfoText>
                  Puedes ponerse en contacto con el equipo de administración al correo administracion@sedee.io y le guiarán de una forma sencilla.
                </InfoText>
                <InfoText>
                  En Sedee, nuestra prioridad es brindarle un servicio transparente y conveniente. Si necesita cancelar una reserva dentro del plazo establecido o tiene alguna pregunta sobre el proceso de devolución, le invitamos a ponerte en contacto con nuestro equipo de atención al cliente.
                </InfoText>
                <InfoText>
                  Estaremos encantados de ayudarle y proporcionarle la asistencia que necesitas para garantizar una experiencia de reserva satisfactoria.
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                  ¿Cuánto tardaré en recibir el reembolso por la cancelación realizada?
                </InfoTitle>
                <InfoText>
                  En Sedee, entendemos la importancia de un proceso de reembolso eficiente y oportuno. Nos esforzamos por procesar los reembolsos de manera expedita para tu comodidad.
                </InfoText>
                <InfoText>
                  El tiempo estimado para procesar los reembolsos por cancelación no superará las 48 horas a partir de la recepción de los datos necesarios. Queremos asegurarnos de que reciba su reembolso de manera rápida y sin demoras innecesarias.
                </InfoText>
                <InfoText>
                  Si tiene alguna pregunta o inquietud específica sobre el estado de tu reembolso, no dude en ponerse en contacto con nuestro equipo de atención al cliente. Estaremos encantados de proporcionarte una actualización y asistencia adicional en caso necesario.
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                  ¿Han cambiado las características del evento, como el número de personas, las fechas o el formato, y el espacio ya no cumple con los requisitos necesarios? ¿Puedo cancelarlo?
                </InfoTitle>
                <InfoText>
                  Sí, puede cancelarlo aceptando las condiciones de cancelación del espacio reservado.
                </InfoText>
            </InfoContainer>
        </Container>
    );
};

export default WhatItsSedee;
