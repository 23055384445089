import React from 'react';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import useConnect from '../connect';
import {CloseIcon, Header, ModalContent} from "../../../../components/Modal/styles";
import Dialog from "@material-ui/core/Dialog";
import {
    ButtonTransfer,
    Section, SectionLi,
    SectionText,
    SectionTitle, SectionUl,
    Strong
} from "../../../Shared/Modals/TransferModal/styles";



const TransferButton = () => {
    const {
        handleSubmitTransfer,
        initialValues,
        handleClose,
        itsOpen,
        booking
    } = useConnect();
    return (
        <>
            <Formik
                onSubmit={handleSubmitTransfer}
                initialValues={initialValues}
            >
                <Form noValidate style={{ height: '100%', width: '100%' }}>
                    <Button disabled={booking?.isTransfered}
                        type="submit"
                        size="big"
                        fullWidth
                        variant="primary"
                    >
                        Hacer transferencia
                    </Button>
                </Form>
            </Formik>
            <>
            { itsOpen && (
                <ModalContent className="modal-ui">
                    <Dialog
                        open={itsOpen}
                        onClose={handleClose}
                    >
                        <Header>
                            <ButtonTransfer type="button" onClick={handleClose}>
                                <CloseIcon />
                            </ButtonTransfer>
                        </Header>
                        {/*<Section>*/}
                        {/*    <SectionTitle>*/}
                        {/*        Transferencia realizada*/}
                        {/*    </SectionTitle>*/}
                        {/*    <SectionText>*/}
                        {/*        ¡Genial! Se ha enviado un correo al equipo de contacto de Sedee con la transferencia. Si te surge alguna duda envía un email a <Strong>transferencias@sedee.com</Strong>*/}
                        {/*    </SectionText>*/}
                        {/*</Section>*/}

                        <Section>
                            <SectionTitle>
                                1. Datos de transferencia
                            </SectionTitle>
                            <SectionText>
                                Datos necesarios para hacer la transferencia
                            </SectionText>
                            <SectionUl>
                                <SectionLi>
                                    Titular de cuenta: <Strong>Selección de Espacios para Eventos S.L.</Strong>
                                </SectionLi>
                                <SectionLi>
                                    Nº de cuenta: <Strong>ES82 0182 1600 2302 0193 1429</Strong>
                                </SectionLi>

                                <SectionLi>
                                    Concepto: <Strong>Nº {booking?.id}</Strong>
                                </SectionLi>
                            </SectionUl>
                        </Section>
                        <Section>
                            <SectionTitle>
                                2. Realiza la transferencia
                            </SectionTitle>
                            <SectionText>
                                Realiza la transferencia de la manera que prefieras.
                                Tienes 48h para realizar en pago del importe.
                            </SectionText>
                        </Section>
                        <Section>
                            <SectionTitle>
                                3. Envianos el comprobante
                            </SectionTitle>
                            <SectionText>
                                Envía el comprobante de pago a <Strong>transferencias@sedee.com</Strong> con tu
                                nombre y el número de reserva del espacio.
                            </SectionText>
                        </Section>
                    </Dialog>
                </ModalContent>
            )}
            </>
        </>
    );
};

export default TransferButton;
