import styled from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultDatePicker from 'components/DatePicker';
import DefaultBaseSelect from 'components/BaseSelect';
import { FiSearch } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

import { from } from 'styles/media';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  ${from.tablet} {
    grid-template-columns: 13.75rem 21rem auto;
  }
`;

export const Select = styled(DefaultBaseSelect)``;

export const DatePicker = styled(DefaultDatePicker)`
  z-index: 2;
`;

export const Button = styled(DefaultButton)`
  align-self: flex-end;
`;

export const SearchIcon = styled(FiSearch)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.75rem;
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-align: right;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
`;
