import styled from 'styled-components';
import { from } from 'styles/media';

const TextStyles = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  * {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    color: ${({ theme }) => theme.colors.ebonyClay};
  }

  a {
    text-decoration-line: underline;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  h1 {
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 2.7rem;
    ${from.tablet} {
      font-size: 3rem;
      line-height: 3.875rem;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 1.6875rem;
    line-height: 2.5313rem;

    ${from.tablet} {
      font-size: 2.25rem;
      line-height: 3.125rem;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 1.3125rem;
    line-height: 1.8375rem;

    ${from.tablet} {
      font-size: 1.75rem;
      line-height: 2.4375rem;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.6875rem;

    ${from.mobile} {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    ${from.mobile} {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }

  h6 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.3rem;
    margin-bottom: 1rem;
  }

  p + p {
    margin-top: 1rem;
  }

  ol,
  ul {
    margin: 1.25rem 1.25rem 2.5rem;
    padding-left: 1.875rem;
    list-style: initial;
  }
`;

export default TextStyles;
