import React, { FC, memo } from 'react';
import Search from 'containers/Shared/Search';
import { Link } from 'react-router-dom';
import { Props } from '../types';
import { Container, Content, Button, DropMenu } from './styles';
import { Logo, Section, LoginModal, RegisterModal, ForgotPassword } from '../styles';
import useConnect from '../connect';

const DesktopTopbar: FC<Props> = ({
  isVisible,
  isAuth,
  allowSearch,
  wide,
  ...props
}) => {
  const {
    open,
    handleOpenModal,
    handleCloseModal,
    handleOpenModalRegister,
    handleOpenModalForgot,
  } = useConnect();

  return (
    <Container $isVisible={isVisible} {...props}>
      <Content>
        <Link to="/">
          <Logo />
        </Link>

        {allowSearch && <Search />}

        <Section>
          {isAuth ? (
            <DropMenu />
          ) : (
            <Button type="button" onClick={handleOpenModal}>
              Iniciar Sesión
            </Button>
          )}
        </Section>
      </Content>

      <LoginModal
        open={open.status && open.modal === 'login'}
        handleClose={handleCloseModal}
        goToRegister={handleOpenModalRegister}
        goToForgot={handleOpenModalForgot}
      />
      <RegisterModal
        open={open.status && open.modal === 'register'}
        handleClose={handleCloseModal}
        goToLogin={handleOpenModal}
      />
      <ForgotPassword
        open={open.status && open.modal === 'forgot'}
        handleClose={handleCloseModal}
      />
    </Container>
  );
};

export default memo(DesktopTopbar);
