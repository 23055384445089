import React, { FC } from 'react';
import {Formik, Field, } from 'formik';
import {
  Container,
  Body,
  Form,
  Input,
  Button,
  Text,
  Bottom,
  RegisterButton, Error,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const Login: FC<Props> = ({ handleClose, goToRegister, goToForgot, open, ...props }) => {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
      error
  } = useConnect({ handleClose });

  if (loading) return <>Loading...</>;

  return (
    <Container
      title="Inicio de sesión"
      open={open}
      handleClose={handleClose}
      {...props}
    >
      <Body>
        {error &&
            (
                <Error>
                  <Text>Email o contraseña inválido</Text>
                </Error>
            )}
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form noValidate>
            <Field
                type="email"
                name="identifier"
                as={Input}
                label="Email" />
            <Field
              type="password"
              name="password"
              as={Input}
              label="Contraseña" />

            <RegisterButton type="button" onClick={goToForgot}>
              ¿Has olvidado tu contraseña?
            </RegisterButton>

            <Button variant="primary" fullWidth type="submit">
              Continuar
            </Button>
          </Form>
        </Formik>
        <Bottom>
          <Text>¿No tienes cuenta?</Text>{' '}
          <RegisterButton onClick={goToRegister}>Regístrate</RegisterButton>
        </Bottom>
      </Body>
    </Container>
  );
};

export default Login;
