import React, { FC } from 'react';
import useConnect from './connect';
import { Container, Select, DatePicker, Button, SearchIcon } from './styles';
import { Props } from './types';

const Search: FC<Props> = ({ showLabels, ...props }) => {
  const {
    currentCity,
    handleCityChange,
    cityOptions,
    to,
    from,
    handleFromChange,
    handleToChange,
    goToSearch,
  } = useConnect();

  return (
    <Container {...props}>
      <Select
        label={showLabels ? 'Ubicación' : undefined}
        value={currentCity}
        onChange={handleCityChange}
        options={cityOptions}
      />
      <DatePicker
        to={to}
        from={from}
        toHandleChange={handleToChange}
        fromHandleChange={handleFromChange}
        labelLeft={showLabels ? 'Entrada' : undefined}
        labelRight={showLabels ? 'Salida' : undefined}
      />
      <Button rightIcon={<SearchIcon />} isCentered onClick={goToSearch}>
        Buscar
      </Button>
    </Container>
  );
};

export default Search;
